import { Grid, Box } from "@mui/material";
import { styled } from "@mui/system";

export const AboutUsGrid = styled(Grid)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  backgroundColor: "#19181C",
  display: "flex",
  overflow: "hidden",
  justifyContent: "center",
  "&:hover .image": {
    transition: "transform 1s ease-in-out",
    transform: "scale(1.05)",
  },
}));

export const ContentWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: "1300px",
  padding: theme.spacing(6),
  alignContent: "center",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  "@media (min-width: 3000px)": {
    maxWidth: "40vw",
  },
}));

export const AboutUsText = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "90%",
  margin: "auto",
  padding: theme.spacing(2),
  ".header": {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginTop: theme.spacing(4),
    },
  },
  ".paragraph": {
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      fontSize: "1em",
    },
  },
  ".strong_primary": {
    color: theme.palette.primary.main,
  },
}));

export const AboutUsImg = styled("img")(({ theme }) => ({
  width: "400px",
  height: "400px",
  borderRadius: "50%",
  objectFit: "cover",
  display: "block",
  transition: "transform 0.3s ease-in-out",
  margin: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "350px",
    height: "350px",
    marginTop: theme.spacing(2),
    margin: "auto",
  },
}));

export const ImageGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
