import { Paragraph, Title } from "@components/base";
import { SolutionsSection, SolutionsGrid, SolutionCard } from "./Solutions.styles";
import { TrendingUp, CurrencyExchange, TableChart, FilterAlt, AutoMode, Psychology } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const icons = {
  sales: <TrendingUp className="icon" />,
  marketing: <CurrencyExchange className="icon" />,
  dashboards: <TableChart className="icon" />,
  funnels: <FilterAlt className="icon" />,
  automation: <AutoMode className="icon" />,
  ai: <Psychology className="icon" />,
};

const Solutions = () => {
  const { t } = useTranslation();
  const solutions = t("solutions_content", { returnObjects: true });

  return (
    <SolutionsSection id="Solutions">
      <meta
        name="description"
        content="The different solutions that we apply at illo solutions, from AI driven apps, to building a whole data architecture"
      />
      <Title className="header">{t("solutions_header")}</Title>
      <br />
      <SolutionsGrid>
        {solutions.map((data, index) => (
          <SolutionCard key={index}>
            {icons[data.icon]}
            <Paragraph className="title">{data.title}</Paragraph>
            <Paragraph className="description">{data.description}</Paragraph>
          </SolutionCard>
        ))}
      </SolutionsGrid>
      <br />
    </SolutionsSection>
  );
};

export default Solutions;
