import { styled } from "@mui/system";
import { Container, Typography } from "@mui/material";
import { forwardRef } from "react";
import { ReactComponent as Logo } from "@components/assets/logo.svg";

export const IlloLogo = (props) => {
  return <Logo {...props} />;
};

const LogoContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "left",
  alignContent: "left",
  gap: theme.spacing(1),
  width: "auto",
  paddingLeft: "0px !important",
  marginLeft: 0,
}));

export const IlloNameStyle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "2rem",
  lineHeight: "1",
  fontFamily: '"Dosis", sans-serif',
  marginLeft: "-7px",
}));

export const IlloNamedLogo = forwardRef((props, ref) => {
  return (
    <LogoContainer ref={ref}>
      <IlloLogo width="50px" height="50px" />
      <IlloNameStyle>illo</IlloNameStyle>
    </LogoContainer>
  );
});
