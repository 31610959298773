import { styled } from "@mui/material/styles";
import { Button, Typography, MenuItem, Toolbar, Box } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "inherit",
  fontWeight: "bold",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#E04070",
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textDecoration: "none",
  color: "inherit",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontWeight: "bold",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

export const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
}));
