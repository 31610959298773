import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "4rem",
  // color: theme.palette.primary[theme.palette.mode],
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.3rem",
  },
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "2rem",
  // color: theme.palette.primary[theme.palette.mode],
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.7rem",
  },
}));

export const Paragraph = styled(Typography)(({ theme }) => ({
  // fontWeight: "bold",
  fontSize: "1rem",
  // color: theme.palette.primary[theme.palette.mode],
  lineHeight: "2",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
  },
}));

export const Image = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  height: "100%",
  objectFit: "cover",
  display: "block",
}));

export const HorizontalDivider = styled(Divider)(({ theme }) => ({
  height: 4,
  backgroundColor: theme.palette.primary.main,
  marginTop: theme.spacing(2),
  width: "100%",
}));
