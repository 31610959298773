import {
  WelcomeTextBox,
  ScrambledSubtitle,
  ScrambledContainer,
  VideoContainer,
  WelcomeContainer,
} from "./Welcome.styles";
import { Grid, Box } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { Title } from "@components/base";
import { useTranslation } from "react-i18next";

const Welcome = () => (
  <WelcomeContainer container>
    <meta name="description" content="Welcome to illo Solutions, data enginering experts, ask for free consultancy" />
    <VideoContainer>
      <video autoPlay muted loop playsInline>
        <source src={`${process.env.PUBLIC_URL}/home/analysis_video.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </VideoContainer>
    <WelcomeText />
  </WelcomeContainer>
);

const ScrambleSubtitle = ({ text, speed }) => {
  const characters = "aB1cD2eF3gH4iJ5kL6mN7oP8qR9sT0uV@wX#yZ*0123456789!%&()[]{}<>?/|^~`+,-.=_";
  const revealSpeed = speed; // milliseconds
  const [charMap, setCharMap] = useState([]);

  const scramble = (text) =>
    text
      .split("")
      .map((char) => (char === " " ? " " : characters.charAt(Math.floor(Math.random() * characters.length))))
      .join("");

  useEffect(() => {
    const scrambledPattern = scramble(text);
    const newCharMap = [];
    for (let index = 0; index < scrambledPattern.length; index++) {
      const value = scrambledPattern[index];
      newCharMap.push({
        value: value,
        revealed: false,
      });
    }
    setCharMap(newCharMap);

    const randomArray = [];
    const availableIndices = Array.from({ length: text.length }, (_, i) => i);
    for (let i = text.length - 1; i >= 0; i--) {
      const randomIndex = Math.floor(Math.random() * (i + 1));
      const index = availableIndices[randomIndex];
      randomArray.push(index);
      availableIndices[randomIndex] = availableIndices[i];
    }
    availableIndices.pop();

    const interval = setInterval(() => {
      if (randomArray.length > 0) {
        const item = randomArray.shift();
        setCharMap((prevCharMap) => {
          const newCharMap = [...prevCharMap];
          newCharMap[item] = {
            ...newCharMap[item],
            revealed: true,
            value: text[item],
          };
          return newCharMap;
        });
      } else {
        clearInterval(interval);
      }
    }, revealSpeed);

    return () => clearInterval(interval);
  }, [text, revealSpeed]);

  return (
    <ScrambledContainer>
      {charMap.map((item, index) => (
        <ScrambledSubtitle
          key={index}
          display={"inline"}
          className={item.revealed ? "subtitle-revealed" : "subtitle-unrevealed"}
        >
          {item.value}
        </ScrambledSubtitle>
      ))}
    </ScrambledContainer>
  );
};

const WelcomeText = () => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12} md={6}>
      <WelcomeTextBox>
        <Box className="text-container">
          <Title>{t("welcome_text")}</Title>
          <WordChanger />
        </Box>
      </WelcomeTextBox>
    </Grid>
  );
};
export default Welcome;

const WordChanger = () => {
  const { t } = useTranslation();
  const wordArray = t("welcome_word_array", { returnObjects: true });

  const [currWord, setCurrWord] = useState(wordArray[0]);
  const [isActive] = useState(true);

  const index = useRef(0);
  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        index.current++;
        setCurrWord(wordArray[index.current]);
        if (index.current === wordArray.length - 1) {
          index.current = -1;
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [wordArray, isActive]);

  return <ScrambleSubtitle speed={60} text={currWord} />;
};
