import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const TeamSection = styled("div")(({ theme }) => ({
  paddingTop: "30px",
  alignContent: "center",
  minHeight: "100vh",
  width: "100%",
  ".cards": {
    margin: "auto",
    width: "80vw",
    "@media (min-width: 1920px)": {
      width: "60vw",
    },
    "@media (min-width: 3000px)": {
      width: "40vw",
    },
  },
  ".header": {
    textAlign: "center",
  },
}));

export const StyledCard = styled(Box)(({ theme }) => ({
  backgroundColor: "rgb(20, 19, 22)",
  display: "block",
  objectFit: "cover",
  maxWidth: "400px",
  // maxHeight: "640px",
  margin: "auto",
  textAlign: "center",
  borderRadius: "25px",
  ".position": {
    fontWeight: "light",
    fontSize: "12px",
  },
  ".name": {
    fontWeight: "bold",
    fontSize: "20px",
    color: theme.palette.primary.main,
  },
  ".icon": {
    color: "#000",
  },
  ".bio": {
    fontSize: "15px !important",
    textAlign: "center",
  },
}));

export const TeamImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  maxHeight: "400px",
  objectFit: "cover",
  display: "block",
  position: "relative",
  borderRadius: "25px 25px 0 0 ", // Top corners
}));
