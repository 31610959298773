import React, { useRef } from "react";
import { styled } from "@mui/system";
// import Box from "@mui/material/Box";
// import Footer from "@components/Footer"; // Import your Footer component
import NavBar, { NavBarContext } from "./NavBar";
// import NavBar from "@components/NavBar/NavBar"; // Import your NavBar component
import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";
import "@i18n/i18n.js";

export const Background = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
}));

export const ContentBox = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // gap: "20px",
  // padding: "30px",
});

function MainLayout({ children }) {
  const navBarRef = useRef(null);

  return (
    <Background>
      <NavBarContext.Provider value={navBarRef}>
        <NavBar navBarRef={navBarRef} />
        <ContentBox>
          {children} {/* Render the children passed to MainLayout */}
        </ContentBox>
        <Footer />
      </NavBarContext.Provider>
    </Background>
  );
}

const LayoutRoute = () => (
  <MainLayout>
    <Outlet /> {/* Child routes will be rendered here */}
  </MainLayout>
);

export default LayoutRoute;
