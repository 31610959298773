import React, { useState } from "react";
import { AppBar, Container, Grow, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { IlloNamedLogo } from "@components/base/Logos";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { StyledButton, StyledTypography, StyledToolbar, StyledBox } from "./Navbar.styles";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags"; // Import react-world-flags

function NavBar({ navBarRef }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [aboutDropdownAnchorEl, setAboutDropdownAnchorEl] = useState(null);
  const [servicesDropdownAnchorEl, setServicesDropdownAnchorEl] = useState(null);
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null); // For language dropdown
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAboutDropdownOpen = (event) => {
    setAboutDropdownAnchorEl(event.currentTarget);
  };

  const handleServicesDropdownOpen = (event) => {
    setServicesDropdownAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAboutDropdownAnchorEl(null);
    setServicesDropdownAnchorEl(null);
  };

  const handleLanguageMenuOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null);
  };

  // Change the language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleLanguageMenuClose();
  };

  const menuItems = [{ label: t("home_label"), href: "/" }];

  const aboutDropdownItems = [
    { label: t("about_us_header"), href: "/#AboutUs" },
    { label: t("why_us_header"), href: "/#WhyUs" },
    { label: t("the_team_header"), href: "/#TheTeam" },
  ];

  const productsDropdownItems = [
    { label: t("services_header"), href: "/#Services" },
    { label: t("solutions_header"), href: "/#Solutions" },
  ];

  const FlagIcon = () => (
    <Flag code={i18n.language === "es" ? "ES" : i18n.language === "pl" ? "PL" : "GB"} width="24" />
  );
  const FlagMenu = () => (
    <Menu anchorEl={languageAnchorEl} open={Boolean(languageAnchorEl)} onClose={handleLanguageMenuClose}>
      <MenuItem onClick={() => changeLanguage("en")}>
        <Flag code="GB" width="24" style={{ marginRight: "8px" }} />
        English
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("es")}>
        <Flag code="ES" width="24" style={{ marginRight: "8px" }} />
        Español
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("pl")}>
        <Flag code="PL" width="24" style={{ marginRight: "8px" }} />
        Polski
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <StyledToolbar disableGutters>
          <Grow in={true} timeout={1000}>
            <div style={{ transformOrigin: "0 0 0" }}>
              <IlloNamedLogo />
            </div>
          </Grow>
          {isMobile ? (
            <>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                <MenuIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {menuItems.map((item) => (
                  <MenuItem key={item.label} onClick={handleMenuClose} component="a" href={item.href}>
                    {item.label}
                  </MenuItem>
                ))}
                <MenuItem aria-haspopup="true" onClick={handleAboutDropdownOpen}>
                  {t("about_label")}
                </MenuItem>
                <Menu
                  id="about-dropdown-menu-mobile"
                  anchorEl={aboutDropdownAnchorEl}
                  open={Boolean(aboutDropdownAnchorEl)}
                  onClose={handleDropdownClose}
                >
                  {aboutDropdownItems.map((item) => (
                    <MenuItem key={item.label} onClick={handleDropdownClose} component="a" href={item.href}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
                <MenuItem aria-haspopup="true" onClick={handleServicesDropdownOpen}>
                  {t("products_label")}
                </MenuItem>
                <Menu
                  id="services-dropdown-menu-mobile"
                  anchorEl={servicesDropdownAnchorEl}
                  open={Boolean(servicesDropdownAnchorEl)}
                  onClose={handleDropdownClose}
                >
                  {productsDropdownItems.map((item) => (
                    <MenuItem key={item.label} onClick={handleDropdownClose} component="a" href={item.href}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
                {/* Language selection for mobile */}
                <MenuItem onClick={handleLanguageMenuOpen}>
                  <FlagIcon />
                </MenuItem>
                <FlagMenu />
                <MenuItem onClick={handleMenuClose}>
                  <StyledButton href="https://calendly.com/illo-data-solutions/30min">{t("book_a_call")}</StyledButton>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <StyledBox>
              {menuItems.map((item) => (
                <StyledTypography key={item.label} component="a" href={item.href}>
                  {item.label}
                </StyledTypography>
              ))}
              <StyledTypography
                aria-controls="services-dropdown-menu"
                aria-haspopup="true"
                onClick={handleServicesDropdownOpen}
                component="span"
                style={{ cursor: "pointer" }}
              >
                {t("products_label")}
              </StyledTypography>
              <Menu
                id="services-dropdown-menu"
                anchorEl={servicesDropdownAnchorEl}
                open={Boolean(servicesDropdownAnchorEl)}
                onClose={handleDropdownClose}
              >
                {productsDropdownItems.map((item) => (
                  <MenuItem key={item.label} onClick={handleDropdownClose} component="a" href={item.href}>
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>

              <StyledTypography
                aria-controls="about-dropdown-menu"
                aria-haspopup="true"
                onClick={handleAboutDropdownOpen}
                component="span"
                style={{ cursor: "pointer" }}
              >
                {t("about_label")}
              </StyledTypography>
              <Menu
                id="about-dropdown-menu"
                anchorEl={aboutDropdownAnchorEl}
                open={Boolean(aboutDropdownAnchorEl)}
                onClose={handleDropdownClose}
              >
                {aboutDropdownItems.map((item) => (
                  <MenuItem key={item.label} onClick={handleDropdownClose} component="a" href={item.href}>
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>

              {/* Language Dropdown */}
              <StyledBox
                onClick={handleLanguageMenuOpen}
                style={{ cursor: "pointer", display: "inline-flex", alignItems: "center" }}
              >
                <FlagIcon />
              </StyledBox>
              <FlagMenu />

              {/* Book a Call Button */}
              <StyledButton href="https://calendly.com/illo-data-solutions/30min">{t("book_a_call")}</StyledButton>
            </StyledBox>
          )}
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}

export default NavBar;
