import { Title, Paragraph, Image } from "@components/base";
import { Box, Grid } from "@mui/material";
import { WhyUsSection } from "./WhyUs.styles";
import { Trans, useTranslation } from "react-i18next";

const WhyUs = () => {
  const { t } = useTranslation();

  return (
    <WhyUsSection id="WhyUs">
      <meta name="description" content="Why should I work with illo? Why no other Data Engineering consultancies?" />
      <Box className="content">
        <Title className="header">{t("why_us_header")}</Title>
        <br></br>
        <Grid container spacing={4}>
          <Grid xs={12} sm={6} md={6} item>
            <Image className="image" src={`${process.env.PUBLIC_URL}/home/whyUs.svg`} />
          </Grid>
          <Grid xs={12} sm={6} md={6} item>
            <Box className="text-container">
              <Paragraph className="paragraph">
                <Trans
                  i18nKey="why_us_content"
                  components={{ strong: <strong className="strong-primary" />, b: <b />, br: <br /> }}
                />
              </Paragraph>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </WhyUsSection>
  );
};

export default WhyUs;
