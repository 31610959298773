import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import "@aws-amplify/ui-react/styles.css";
import { ThemeProvider, CssBaseline, GlobalStyles } from "@mui/material";

// Views
import Home from "@views/home/Home";

import { darkTheme } from "./themes/themes";
import LayoutRoute from "@components/Layout";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { backgroundColor: darkTheme.palette.background },
        }}
      />
      <Routes>
        <Route element={<LayoutRoute />}>
          <Route path="/" element={<Home />} />
        </Route>
      </Routes>
    </ThemeProvider>
  </BrowserRouter>
);
