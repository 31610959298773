// Footer.styles.js
import { styled } from "@mui/system";
import { Box, Grid, Link, Typography } from "@mui/material";
import { IlloLogo } from "@components/base/Logos";

export const FooterContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  color: theme.palette.common.white,
  marginTop: theme.spacing(6),
  padding: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(2),
  },
}));

export const FooterLink = styled(Link)(({ theme }) => ({
  display: "block",
  color: theme.palette.common.white,
  marginBottom: theme.spacing(1),
  fontSize: "0.875rem",
  "&:hover": {
    color: theme.palette.grey[400],
  },
}));

export const FooterTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
}));

export const IconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}));

export const IlloIcon = styled(IlloLogo)(({ theme }) => ({
  width: 64,
  height: 64,
  color: theme.palette.grey[400],
  "& path": {
    strokeWidth: 1000,
  },
  marginRight: theme.spacing(1),
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
}));
