import { Title, Paragraph, HorizontalDivider } from "@components/base";
import { ServicesSection, ServicesCard, ExplanationBox, AccordionSummary, AccordionDetails } from "./Services.styles";
import { Accordion, Button, CardContent, Grid } from "@mui/material";
import { Explore, Hiking, PrecisionManufacturing } from "@mui/icons-material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";

const icons = {
  starting: <Explore className="icon" />,
  scale: <Hiking className="icon" />,
  ai: <PrecisionManufacturing className="icon" />,
};

const Services = () => {
  const { t } = useTranslation();
  const services = t("services_content", { returnObjects: true });

  return (
    <ServicesSection id="Services">
      <meta
        name="description"
        content="The data services that we offer at illo solutions, full data stack, expert consultation"
      />
      <Title className="header">{t("services_header")}</Title>
      <Grid container className="service-cards">
        {services.map((data, index) => (
          <ServiceCard item xs={12} sm={6} md={4} key={index} {...data} />
        ))}
      </Grid>
      <br />
      <ExplanationBox>
        <Paragraph className="paragraph">
          <Trans i18nKey="services_explanation" components={{ br: <br /> }} />
        </Paragraph>
        <br />
        <Button className="calendly-button" href="https://calendly.com/illo-data-solutions/30min">
          {t("book_a_call_now")}
        </Button>
      </ExplanationBox>
    </ServicesSection>
  );
};

export const ServiceCard = ({ icon, title, description, includes }) => {
  const [expanded, setExpanded] = React.useState("");

  const handleMouseEnter = (panel) => () => {
    setExpanded(panel);
  };

  const handleMouseLeave = () => {
    setExpanded("");
  };

  return (
    <ServicesCard>
      <CardContent>
        {icons[icon]}
        <Paragraph className="title">{title}</Paragraph>
        <Paragraph className="description">{description}</Paragraph>
        <HorizontalDivider />
        {includes.map((data, index) => (
          <Accordion
            expanded={expanded === "panel" + index}
            onMouseEnter={handleMouseEnter("panel" + index)}
            onMouseLeave={handleMouseLeave}
            disableGutters={true}
            key={index}
          >
            <AccordionSummary aria-controls={"panel" + index + "d-content"} id={"panel" + index + "d-header"}>
              <Paragraph className="includes_title">{data.includes_title}</Paragraph>
            </AccordionSummary>
            <AccordionDetails>
              <Paragraph className="includes_description">{data.includes_description}</Paragraph>
            </AccordionDetails>
          </Accordion>
        ))}
      </CardContent>
    </ServicesCard>
  );
};

export default Services;
