import React from "react";
import { Container, Box, Typography, Link } from "@mui/material";
import { LinkedIn } from "@mui/icons-material";
import { FooterContainer, FooterLink, FooterTitle, IconContainer, IlloIcon, LogoBox } from "./Footer.styles";
import { IlloNameStyle } from "@components/base/Logos";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterContainer component="footer">
      <Container maxWidth="lg">
        <Box display="flex" flexDirection={{ xs: "column", md: "row" }} py={6}>
          <Box flex={1} mb={{ xs: 4, md: 0 }}>
            <LogoBox display="flex" alignItems="center" mb={2}>
              <IlloIcon />
              <IlloNameStyle variant="h6" component="span" ml={2}>
                illo Solutions
              </IlloNameStyle>
            </LogoBox>
            <Typography variant="body2" color="text">
              {t("footer_content")}
            </Typography>
          </Box>
          <Box display="flex" flex={1} justifyContent="space-around">
            <Box>
              <FooterTitle variant="h6">{t("quick_links_label")}</FooterTitle>
              <nav>
                <FooterLink href="#">{t("home_label")}</FooterLink>
                <FooterLink href="#AboutUs">{t("about_us_header")}</FooterLink>
                <FooterLink href="#Solutions">{t("solutions_header")}</FooterLink>
                <FooterLink href="#WhyUs">{t("why_us_header")}</FooterLink>
                <FooterLink href="#Services">{t("services_header")}</FooterLink>
                <FooterLink href="#TheTeam">{t("the_team_header")}</FooterLink>
                <FooterLink href="https://calendly.com/illo-data-solutions/30min" target="_blank">
                  {t("contact_label")}
                </FooterLink>
              </nav>
            </Box>
            <Box>
              <FooterTitle variant="h6">{t("follow_us_label")}</FooterTitle>
              <IconContainer>
                {/* <Link href="#" color="inherit">
                  <Twitter />
                </Link> */}
                <Link href="http://linkedin.com/company/illo-solutions" target="_blank" color="inherit">
                  <LinkedIn />
                </Link>
                {/* <Link href="#" color="inherit">
                  <Facebook />
                </Link> */}
              </IconContainer>
            </Box>
          </Box>
        </Box>
        <Box textAlign="center" borderTop={1} borderColor="grey.800" pt={4}>
          <Typography variant="body2" color="text">
            {t("copyright_label")}
          </Typography>
        </Box>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
