import { Grid, Box } from "@mui/material";
import { styled } from "@mui/system";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

export const ServicesSection = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(6),
  textAlign: "center",
  minHeight: "100vh",
  alignContent: "center",
  ".service-cards": {
    display: "flex",
    gap: theme.spacing(4),
    justifyContent: "space-evenly",
  },
}));

export const ServicesCard = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
  backgroundColor: "rgb(20, 19, 22)",
  maxWidth: "400px",
  marginTop: "20px",
  textAlign: "center",
  borderRadius: "25px",
  ".title": {
    maxWidth: "400px",
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  ".includes": {
    textAlign: "left",
    maxWidth: "400px",
    fontWeight: "bold",
  },
  ".description": {
    maxWidth: "400px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
    height: "170px",
  },
  ".divider": {
    maxWidth: "400px",
  },
  ".icon": {
    fontSize: "10rem",
    color: theme.palette.primary.main,
  },
  ".includes_title": {
    textAlign: "left",
    fontWeight: "bold",
  },
  ".includes_description": {
    textAlign: "center",
    color: "black",
    fontWeight: "200",
    fontSize: "1em",
  },
}));

export const ExplanationBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  ".calendly-button": {
    backgroundColor: theme.palette.primary.main,
    color: "inherit",
    fontWeight: "bold",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#E04070",
    },
  },
  ".paragraph": {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: theme.palette.common.white,
  transition: "background-color 0.3s ease-in-out, padding 0.3s ease-in-out", // Add smooth transition
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon className="arrow" />} {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
  transition: "box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out", // Add smooth transition
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    transition: "transform 0.3s ease-in-out", // Smooth rotation transition
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    transition: "margin-left 0.3s ease-in-out", // Smooth margin transition
  },
  ...theme.applyStyles("dark", {
    backgroundColor: theme.palette.text.primary.main,
  }),
  ".arrow": {
    color: theme.palette.primary.main,
    fontSize: "0.9",
    transition: "color 0.3s ease-in-out", // Smooth color transition for arrow
  },
}));

export const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    transition: "border 0.3s ease-in-out", // Add smooth border transition
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  })
);
