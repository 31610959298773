import { Box, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const SolutionsSection = styled(Stack)(({ theme }) => ({
  width: "80vw",
  minHeight: "100vh",
  display: "block",
  marginTop: theme.spacing(4),
  justifyContent: "center",
  textAlign: "center",
  alignContent: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100vw",
    minHeight: "100svh",
  },
}));

export const SolutionsGrid = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: theme.spacing(4),
}));

export const SolutionCard = styled(Box)(({ theme }) => ({
  backgroundColor: "rgb(20, 19, 22)",
  width: "30%",
  minWidth: "250px",
  height: "auto",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "25px",
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
  ".title": {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  ".description": {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
  ".icon": {
    fontSize: "10rem",
    color: theme.palette.primary.main,
  },
  "&:hover": {
    transition: "background-color 0.5s ease-in-out",
    backgroundColor: theme.palette.primary.main,
    ".title, .icon": {
      color: "black",
    },
    ".description": {
      color: "white",
    },
  },
}));
