import { Title } from "@components/base";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";

export const WelcomeSection = Grid;

export const WelcomeTextBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "auto",
  padding: "30px",
  marginLeft: "5vw",
  alignContent: "center",
  color: theme.palette.common.white,
  [theme.breakpoints.down("sm")]: {
    paddingTop: "50px",
    marginLeft: "-5vw",
  },
  ".text-container": {
    marginLeft: theme.spacing(4),
  },
}));

export const ScrambledSubtitle = styled(Title)(({ theme }) => ({
  fontSize: "4em",
  display: "inline",
  "&.subtitle-revealed": {
    color: theme.palette.common.white,
  },
  "&.subtitle-unrevealed": {
    color: theme.palette.primary.main,
  },
}));

export const ScrambledContainer = styled(Box)(({ theme }) => ({
  textAlign: "left",
}));

export const VideoContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  zIndex: -1,
  overflow: "hidden",
  video: {
    position: "absolute",
    left: "50%",
    top: "50%",
    width: "100vw",
    height: "100%",
    objectFit: "cover",
    opacity: 0.3,
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.up("lg")]: {
      width: "100vw",
      height: "100vh",
      top: "0",
      transform: "translate(-50%, 0)",
    },
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#745CEE",
    opacity: 0.3,
    zIndex: 1,
    [theme.breakpoints.up("lg")]: {
      height: "100vh",
    },
  },
}));

export const WelcomeContainer = styled(Grid)(({ theme }) => ({
  alignContent: "center",
  height: "95vh",
  [theme.breakpoints.down("sm")]: {
    height: "95svh",
  },
}));
