// // src/views/theme.js
import { createTheme } from "@mui/material/styles";

export const darkTheme = createTheme({
  typography: {
    fontFamily: '"Sora", sans-serif',
    color: "red",
  },
  palette: {
    mode: "dark",
    primary: { main: "#745CEE" }, // Old Purple: #AB96FF // New Purple: 745CEE
    secondary: { main: "#F4564B" },
    background: { default: "#000000" },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "black",
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  typography: {
    fontFamily: '"Sora", sans-serif',
  },
  palette: {
    mode: "light",
    primary: { main: "#745CEE" }, // Old Purple: #AB96FF // New Purple: 745CEE
    secondary: { main: "#F4564B" },
    background: { default: "#FFFFFF" },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "white",
        },
      },
    },
  },
});
