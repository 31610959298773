import { Title, Paragraph } from "@components/base";
import { AboutUsGrid, AboutUsText, AboutUsImg, ContentWrapper, ImageGrid } from "./AboutUs.styles";
import { Grid } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <AboutUsGrid container id="AboutUs">
      <meta
        name="description"
        content="We are a team of experienced professionals who can increase your data systems efficiency, and decrease the cost of your infrastructure "
      />
      <ContentWrapper>
        <Grid container spacing={2}>
          <Grid item md={7} alignContent={"center"}>
            <AboutUsText>
              <Title className="header">{t("about_us_header")}</Title>
              <br />
              <Paragraph className="paragraph">
                <Trans
                  i18nKey="about_us_content"
                  components={{ strong: <strong className="strong_primary" />, b: <b />, br: <br /> }}
                />
              </Paragraph>
            </AboutUsText>
          </Grid>
          <ImageGrid item md={5} xs={12}>
            <AboutUsImg className="image" src={`${process.env.PUBLIC_URL}/home/aboutUs.jpg`} alt="dashboard" />
          </ImageGrid>
        </Grid>
      </ContentWrapper>
    </AboutUsGrid>
  );
};

export default AboutUs;
