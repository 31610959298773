import React from "react";
import { Grid } from "@mui/material";
import { CardContent, IconButton } from "@mui/material";
import { LinkedIn } from "@mui/icons-material";
import { StyledCard, TeamImage, TeamSection } from "./TheTeam.styles";
import { Paragraph, Title } from "@components/base";
import { useTranslation } from "react-i18next";

const TheTeam = () => {
  const { t } = useTranslation();
  const theTeam = t("the_team_content", { returnObjects: true });

  return (
    <TeamSection id="TheTeam">
      <meta
        name="description"
        content="This is the illo solutions team, they are data experts with experience in plenty of business fields"
      />
      <Title className="header">{t("the_team_header")}</Title>
      <Grid container className="cards" spacing={3}>
        {theTeam.map((data, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} paddingLeft={"0px !important"}>
            <TeamCard {...data} />
          </Grid>
        ))}
      </Grid>
    </TeamSection>
  );
};

export const TeamCard = ({ position, name, bio, bio2, picture, linkedInURL }) => (
  <StyledCard>
    <TeamImage alt="picture" src={`${process.env.PUBLIC_URL}/home/team/${picture}`} />
    <CardContent>
      <Paragraph className="position">{position}</Paragraph>
      <Paragraph className="name">{name}</Paragraph>
      <Paragraph className="bio">{bio}</Paragraph>
      <Paragraph className="bio">{bio2}</Paragraph>
      <IconButton component="a" className="icon" href={`//${linkedInURL}`} target="_blank" rel="noopener noreferrer">
        <LinkedIn htmlColor="white" />
      </IconButton>
    </CardContent>
  </StyledCard>
);

export default TheTeam;
