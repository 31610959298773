import React from "react";
import { Welcome, AboutUs, Services, Solutions, TheTeam, WhyUs } from "@components/Home";

function Home() {
  return (
    <>
      <Welcome />
      <AboutUs />
      <Solutions />
      <WhyUs />
      <Services />
      <TheTeam />
    </>
  );
}

export default Home;
