import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const WhyUsSection = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  backgroundColor: "#19181C",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  ".content": {
    alignContent: "center",
    width: "100%",
    maxWidth: "1200px",
    padding: theme.spacing(4),
    "@media (min-width: 1920px)": {
      maxWidth: "50vw",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
  },
  ".strong-primary": {
    color: theme.palette.primary.main,
  },
  ".image": {
    maxHeight: "550px",
    display: "inline",
    transition: "transform 0.3s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "550px",
    },
  },
  "&:hover .image": {
    transition: "transform 1s ease-in-out",
    transform: "scale(1.1)",
  },
  ".text-container": {
    textAlign: "justify",
    alignContent: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      textAlign: "justify",
    },
  },
  ".paragraph": {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
}));
